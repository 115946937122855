.CorrosionContainer{
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 85vh;
    background-color: #383636;
    border-radius: 1rem;
    padding: 2.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-sizing: border-box;
    overflow: scroll;
    overflow-x: hidden;
}

.CorrosionContainer .HeaderContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3rem;
    //align-items: center;
    justify-content: space-between;
}

.CorrosionContainer .TextStyle{
    font-size: 1.5rem;
    color: white;
}

.CorrosionContainer .buttonCorrosion{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 3rem;
    border-radius: 1rem;
    cursor: pointer;
    background-color: #f36934;
    user-select: none;
}

.CorrosionContainer .GraphsContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 40vh;
    background-color: #4d4a4a;
    justify-content: space-between;
    border-radius: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-sizing: border-box;
}

.CorrosionContainer .row{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 32vh;
    box-sizing: border-box;
}

.CorrosionContainer .column{
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}

.CorrosionContainer .LineRow{
    display: flex;
    background-color: beige;
    height: 1px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    width: 100%;
    z-index: 9999;
}

.filterModalContainer {
    display: flex;
    height: 23%;
    width: 20%;   
}

.filterModalButtonWrapper {
    display: flex;
    flex-direction: row;
    height: 20%;
    width: 70%;
    margin: 5% 0;
    justify-content: space-around;
}