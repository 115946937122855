.RequestContainer{
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  justify-content: flex-start;
  width: 45%;
  height: 85%;
  overflow: auto;
  background-color: #383636;
  border-radius: 1rem;
  padding: 1rem;
  box-sizing: border-box;
}


.text-client{
    display: flex;
    flex: 1;
    color:#F5F5F5;
    font-size: 1.75rem;
    font-weight: bold;
}

.cleanButtonRequest{
  color: #f36934;
  cursor: pointer;
  text-decoration: underline;
}

.cleanButtonRequest:hover{
  scale: 1.05;
}

.text-client2{
    color:#F5F5F5;
    width: 29rem;
    margin-left: 2.3rem;

    padding: 0.625rem;
    align-self: flex-start;
    margin-bottom: 1.25rem;
    font-size: 0.75rem;
    font-weight: bold;
  
}
.content-client {
    display: flex;
    align-items: center;
}  


.client::placeholder {
  color: #A4A2A2; /* Cor do placeholder */
}


.button-client:hover {
  background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

body.client {
  margin: 0;
  padding: 0;
}

label.client {
  display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
  text-align: right; /* Alinha o texto à direita */
  margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
  font-size: 0.75rem;
  color: #F5F5F5;
}

a.client {
  color: #F5F5F5
}

.buttonrequest{
  width: 85%;
  height: 3.5rem;
  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-top: 1.5rem;
  margin-bottom: 0.625rem;
}