.rbc-header {
    color: white;
    opacity: .95;
  }
  
  .rbc-today {
    color: black;
    background-color: #e1a58e !important;
    opacity: 1;
  }
  
  .rbc-event {
    color: black;
    border-radius: 5px;
    padding: 4px;
    font-size: 16px;
  }
  
  .rbc-btn-group button {
    color: white;
    opacity: .95;
  }
  
  .rbc-toolbar-label {
    color: white;
    opacity: .95;
  }
  
  .rbc-toolbar button {
    color: white;
    opacity: .95;
  }
  
  .rbc-btn-group .rbc-today {
    color: white;
    opacity: .95;
  }
  
  .rbc-toolbar .rbc-btn-group button {
    color: white;
    opacity: .95;
  }
  
  .rbc-time-slot {
    border: none !important; 
  }
  
  .rbc-selected {
    color: black !important;
    background-color: black !important; /* Substitua #novaCor pelo código hexadecimal da cor desejada. */
  }
  
  .rbc-active{
    color: black !important;
  }
  
  .rbc-off-range-bg{
    background-color: gray !important;
    opacity: .8;
  }
  
  .rbc-button-link{
    color: #4ca37e !important;
    
  }