.scrollViewForm{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color:#888 #2c2c2c; /* thumb and track color */
}