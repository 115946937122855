/* MAIN STYLES */ 
.MainContainer {
  display: flex;
  flex-direction: row;
  background-color: #2C2C2C;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
}

.CentralBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.SideBarCompensation{
  padding: 7vw;
}
/* MAIN STYLES */


.content_Home {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 80%;
  height: 90%;
}

.mainHomeContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upperSide_Home{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 50vh
}

.lowerSide_Home{
  display: flex;
  flex-direction: column;
  background-color: #383636;
  width: 100%;
  height: 35vh;
  border-radius: 10px;

  box-sizing: border-box;
  padding: 1.5rem;
  
  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #2c2c2c #2c2c2c; /* thumb and track color */
}

.block_Home {
  display: flex;
  flex-direction: column;
  background-color: #383636;
  width: 38%;
  height: 100%;
  
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1.5rem;
}

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 4px; /* Largura da bFarra de rolagem */
  padding: auto;
}

::-webkit-scrollbar-track {
  background: #383636; /* Cor do track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Cor do thumb */
  border-radius: 4px; /* Bordas arredondadas do thumb */
}

.all_Home {
  display: flex;
  align-items: center;
}

.centralBlock_Home {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.inspectorList_Home {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
}

.inspectorList_Home .item_Home span {
  font-size: 75%;
}

.phantom-span {
  opacity: 0;
}

.line_Home {
  display: flex;
  background-color: #F5F5F5; 
  width: 100%;
  padding: .05rem; 
}

.item_Home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  color: #F5F5F5;
  height: 100%;
  width: 100%;
}

.icon_Home {
  font-size: 2em; /* Aumenta o tamanho do ícone para 2 vezes o tamanho padrão */

}

.iconinline1_Home {
  display: flex;
  flex-direction: column;
  align-self: center;
  color: #F36934;
}

.iconinline1_Home.hover {
  float: right;
  color: #F36934;
}

.iconinline2_Home {
  float: right;
  color: #49B118;
}

.sincro_Home {
  float: right;
  font-size: calc(4px + .5vw);
}

.iconinline3_Home {
  float: right;
  color: #FF0404;
}

.title_Home {
  display: flex;
  flex-direction: column;
  font-size: 24px;
  width: 100%;
  color: #F5F5F5;
  font-size: calc(12px + .5vw);
}

.date_Home {
  display: flex;
  width: 83%;
  justify-self: center;
  align-self: center;
}

button.arrow_Home {
  color: #F5F5F5; /* Cor do texto do botão */
  border: none; /* Remove a borda padrão do botão */
  width: 20px; /* Largura do botão (ajuste conforme necessário) */
  height: 20px; /* Altura do botão (ajuste conforme necessário) */
  cursor: pointer; /* Transforma o cursor em uma mão quando passa sobre o botão */
  font-size: 12px; /* Tamanho da fonte do texto (ajuste conforme necessário) */
  margin-left: 0.5vh; 
  background-color: #383636;
  float: right;
}

button.arrow_Home:hover {
    background-color: #383636;
    color: #F5F5F5;
    cursor: pointer;
    transform: scale(1.1);
    opacity: 0.9;
}

button.centralButton_Home {
  display: flex;
  flex-direction: row;
  background-color: #383636;
  color: #F5F5F5;
  font-size: calc(7px + .5vw);
  border: none;
  border-radius: 10px;

  box-sizing: border-box;
  padding: 10%;

  width: 100%;
  height: 30%;
  display: flex; 
  align-items: center;
  justify-content: center;
}

.centralButton_Home div.button-text {
  margin-left: 15px;
  font-size: calc(5px + .5vw);
  width: 80%;
}

@media (max-width: 768px) {
  button.centralButton_Home {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .centralBlock_Home  div.button-text {
    margin-left: 0px !important;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

button.centralButton_Home:hover {
  background-color: #D9D9D9;
  color: #2C2C2C;
  cursor: pointer;
}

button.centralButton_Home:disabled {
  background-color: #666666;
  opacity: 0.5;
}

button.centralButton_Home:disabled:hover {
  color: #F5F5F5;
  cursor: not-allowed;
}

body._Home {
  margin: 0;
  padding: 0;
}

img.logo_Home {
  max-width: 100%;
  height: 65px;
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;
}

