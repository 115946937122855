/* src/App.css */


.clientText{
  color: white;
  font-size: 1.5rem;
  align-self: center;
  font-weight: bold;
}

.clientText2{
    color:#F5F5F5;
    width: 29rem;
    margin-left: 2.3rem;
    padding: 0.625rem;
    align-self: flex-start;
    font-size: 0.75rem;
    font-weight: bold;
}

.iconContainer {
  display: flex;        /* Define a div como um container flexível */
  align-items: flex-start;  /* Alinha os itens verticalmente ao centro */
  width: 100%;
}

.folderContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 2rem;
    background-color: #C4C4C4;
    border-radius: 0.7rem;
    cursor: pointer;
    align-self: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.8rem;
    float: right;
}

.form-container-client {
  display: flex;
  align-self: center;
  flex-direction: column;
  background-color: #383636;
  border-radius: 0.625rem;

  width: 45%;
  height: 90%;
  max-height: 50rem;
  padding: 2rem;
  box-sizing: border-box;

  align-items: center;
  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #888 #383636; /* thumb and track color */
}
/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.form-container-client::-webkit-scrollbar {
  width: 4px;
}

.form-container-client::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.form-container-client::-webkit-scrollbar-thumb:hover {
  background-color: #383636;
}



.client::placeholder {
  color: #A4A2A2; /* Cor do placeholder */
}

.button-client {
  width: 85%;
  flex-shrink: 0;
  height: 3rem;

  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-bottom: 0.625rem;
}

.button-client:hover {
  background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

body.client {
  margin: 0;
  padding: 0;
}

label.client {
  display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
  text-align: right; /* Alinha o texto à direita */
  margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
  font-size: 0.75rem;
  color: #F5F5F5;
}

a.client {
  color: #F5F5F5
}
