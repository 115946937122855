.EquipmentMainContainer{
  display: flex;
  flex-direction: column;
  background-color: #383636;
  align-items: center;
  justify-content: flex-start;
  border-radius: 1rem;
  
  width: 45%;
  height: 90%;
  max-height: 50rem;
  padding: 2rem;
  box-sizing: border-box;

  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #888 #383636; /* thumb and track color */
}

.EquipmentMainContainer::-webkit-scrollbar {
  width: 4px;
}

.EquipmentMainContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.EquipmentMainContainer::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}

.input-container-equipment{

  flex-direction: row;
  width: 85%;
  justify-content: center;
  align-items: center;
}

.info-equipment{
  display: flex;
  flex-direction: row;
  width: 47%;
  border-radius: 1rem;
  padding: .6rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
}

.equipmentText{
  color: white;
  font-size: 1.5rem;
  align-self: center;
  font-weight: bold;
}

.equipmentText2{
    color:#F5F5F5;
    align-self: flex-start;
    font-size: 0.75rem;
    font-weight: bold;
}

.text-equipment{
  color:#F5F5F5;
  width: 29rem;
  margin-left: 2.3rem;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: bold;
  align-self: flex-start ;
}

.text-equipment2{
  display: flex;
  flex-direction: column;
  color:#F5F5F5;
  width: 85%;
  margin-bottom: 1.25rem;
  font-size: 0.75rem;
  font-weight: bold;
}

/* Estiliza a barra de rolagem para navegadores Webkit (Chrome, Safari, etc.) */
.form-container-equipment::-webkit-scrollbar {
  width: 4px;
}

.form-container-equipment::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.form-container-equipment::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}


.equipment::placeholder {
  color: #A4A2A2; /* Cor do placeholder */
}

.button-equipment-new {
  flex-shrink: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1.5rem;
  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-bottom: 0.625rem;
}

.button-equipment-new:hover {
  background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

label.equipment {
  display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
  text-align: right; /* Alinha o texto à direita */
  margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
  font-size: 0.75rem;
  color: #F5F5F5;
}

a.equipment {
  color: #F5F5F5
}

