.flag-selector {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 10px;
    right: 20px;
    align-items: center;
    /* width: 5.5rem; */
    height: 1.1rem;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

.flag-selector img {
    display: flex;
    cursor: pointer;
    height: 100%; 
}

.flagContainer{
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    /* width: 25%; */
    height: 100%;
}

.signOutContainer{
    color: white;
    height: 100%;
    width: 100%;
}

.flagImg{
    display: flex;
    width: 100%;
    height: 100%;
}

.flag-dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 100%;
    top: 100%;
}

.flag-dropdown img {
    display: block;
    background-color: #2c2c2c;
    width: 100%;
}

.custom-button-question-container{
    display: flex;
    cursor: pointer;
    background-color: #383636;
    border-radius: 2rem;
    width: 25%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.custom-button-question-container:hover{
    background-color: #555555;
}