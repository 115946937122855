/* src/App.css */


.PlantMainContainer::-webkit-scrollbar {
  width: 4px;
}

.PlantMainContainer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.PlantMainContainer::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}


.text-plant{
  color:#F5F5F5;
  width: 29rem;
  margin-left: 2.3rem;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
  font-size: 1.75rem;
  font-weight: bold;
  align-self: flex-start ;

}

.content-plant {
  display: flex;
  align-items: center;
}  

.form-container-plant {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 45%;
  height: 90%;
  max-height: 50rem;
  padding: 2rem;
  box-sizing: border-box;
  
  background-color: #383636;
  border-radius: 0.625rem;

  max-height: 85vh;  /* Ajuste conforme necessário */
  overflow-y: auto;  /* Isso permite a rolagem vertical */
  overflow-x: hidden;  /* Isso permite a rolagem vertical */
  scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
  scrollbar-color: #888 #383636; /* thumb and track color */
}

.form-container-plant::-webkit-scrollbar {
width: 4px;
}

.form-container-plant::-webkit-scrollbar-thumb {
background-color: #888;
border-radius: 4px;
}

.form-container-plant::-webkit-scrollbar-thumb:hover {
  background-color: #747070;
}

.emailModalContainer {
  width: 30%;
  height: 50%;
  margin-right: 16rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.PageModalTextContainer {
  color: white;
}

.PageModalButtonContainer {
  display: flex;
  color: white;
  background-color: #f36934;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  width: 40%
}

.PageModalButtonContainer:hover {
  background-color: #C25429;
}

.textsModalContainer {
  display: flex;
  width: 75%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modalInputText {
  display: flex;
  padding: 1% 0%;
  width: 75%;
}

.copyIconModal {
  display: flex;
  position: absolute;
  font-size: 1.5em;
  margin: 2% 0 0 70%;
  color: #2C2C2C
}

.copyIconModal:hover {
  cursor: pointer;
  transform: scale(1.005);
  opacity: 0.9;
}

.copyIconModalDisabled {
  display: flex;
  position: absolute;
  font-size: 1.5em;
  padding: 2% 0 0 70%;
  color: #2C2C2C;
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.modalButtonWrapper {
  display: flex;
  flex-direction: row;
  height: 10%;
  width: 40%;
  margin: 2% 0;
  justify-content: space-around;
}

.customInputFlex{
  width: 100%;
  height: 2.2rem;
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1.5rem;
}

.plant {

width: 28rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
height: 1.5rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
max-width: 100%; /* Garante que o input não ultrapasse a largura do contêiner pai */
margin-bottom: 0rem; /* Espaçamento inferior */
background-color: #FFFFFF;
border-radius: 1rem; /* Bordas arredondadas */
color: black;
margin-top: 1.5rem; /* Espaçamento superior */
font-size: 0.8rem; /* Tamanho da fonte base em 'rem' para elementos internos */
padding: 0.5rem 0.7rem; /* Espaçamento interno */
}

.plant::placeholder {
color: #A4A2A2; /* Cor do placeholder */
}

.button-plant {
  display: flex;
  flex-direction: column;
  width: 85%;
  flex-shrink: 0;
  height: 3rem;
  background-color: #F36934; /* Cor de fundo do botão */
  color: #fff; /* Cor do texto do botão */
  border: none;
  cursor: pointer;
  border-radius: 0.625rem;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
}

.button-plant:hover {
  background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

body.plant {
margin: 0;
padding: 0;
}

label.plant {
display: block; /* Define as labels como blocos para que ocupem toda a largura disponível */
text-align: right; /* Alinha o texto à direita */
margin-bottom: 0.313rem; /* Adiciona um espaço entre a label e o input */
font-size: 0.75rem;
color: #F5F5F5;
}



a.plant {
color: #F5F5F5
}
@media(max-width:350px)
{

.text-plant{
  font-size: 1.3rem;
  margin-left: 0.1rem;


}
.text-plant2{
  font-size: 0.6rem;
  margin-left: 0.1rem;

  

}
.form-container-plant {
  width: 10rem;
  align-content: start;


}



.button-plant{
  width: 9rem;


}
.plant{
  width:9rem;
  margin-left: 0;
}

}
@media ((min-width:350px) and (max-width: 550px)) {


.text-plant{
  font-size: 1rem;
  margin-left: 0.6rem;



}
.text-plant2{
  font-size: 0.5rem;
  margin-left: 0.6rem;



}
.form-container-plant {
  width: 16rem;
  align-content: start;
}
.button-plant{
  width:13.5rem;


}
.plant{
  width:13.5rem;
}


}

@media (min-width: 550px) and (max-width: 1130px) {
.text-plant{
  font-size: 1.5rem;
  padding: 0rem;
  margin-left: 0.5rem;


}
.text-plant2{
  font-size: 0.6rem;
  padding: 0rem;
  margin-left: 0.5rem;

}
.form-container-plant {
  width: 20rem;
  align-content: start;
}
.button-plant{
  width: 19rem;


}
.plant{
  width: 19rem;
}
}



