.Region{
    display: flex;
    flex-direction: column;
    background-color: #2C2C2C;
    width: 100%;
    height: 100vh;
}

.mainRegionContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 14vw;
}

.grayContainer{
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 65%;
    height: 80%;
    background-color: #383636;
    border-radius: 1rem;
    padding: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.titleText{
    color: white;
    font-size: 1.5rem;
    font-family: Arial, Helvetica, sans-serif;
}

.iconLeft{
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
    align-self: center;
}

.iconLeft:hover {
    cursor: pointer;
    transform: scale(1.1);
    opacity: 0.9;
}

.cameraContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C4C4C4;
    border-radius: 1rem;
    align-self: center;
    cursor: pointer;
}

.cameraIcon{
    font-size: 2rem;
    color: gray;
    cursor: pointer;
}

.line{
    background-color: white;
    height: .15rem;
    width: 100%;
}

.iconarrow_Home_disabled {
    opacity: 0.5;
}

.iconarrow_Home_disabled:hover {
	cursor: not-allowed;
}

.optionText{
    display: flex;
    flex-direction: column;
    font-size: 1.1rem;
    width: 15%;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    align-self: center;
    justify-content: center;
    align-items: center;
}

.scrollView{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40%;
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

.scrollView::-webkit-scrollbar {
    width: 2px;
}
  
.scrollView::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.individualText{
    color: white;
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
}

.barContainerFilled{
    display: flex;
    background-color: #F36934;
    height: 3vh;
    border-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.barContainerEmpty{
    display: flex;
    background-color: white;
    height: 3vh;
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.coloredBox{
    width: 1rem;
    height: 1rem;
    border-width: 2px;
    align-self: center;
}

.coloredIcon{
    align-self: center;
    font-size: 1.3rem;
}

.labelText{
    color: aliceblue;
    align-self: center;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.generalOptionsTitle{
    color: white;
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
}

.visualInspectionContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    opacity: .8;
}

.visualInspectionContainer .inspectionOption{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-right: 1rem;
    box-sizing: border-box;
    align-items: center;
}

.visualInspectionContainer .documentLogoButton{
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
    user-select: none;
}

.visualInspectionContainer .documentLogoButton:hover{
    scale: 1.1;
}