.Calendar{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: #2C2C2C;
}

.downArrow{
  display: flex;
  align-self: center;
  font-size: 1.3rem;
  padding-left: 1rem;
}

.itemsContainer{
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 80%;
    background-color: #383636;
    border-radius: 1rem;
    padding: 2rem;
    box-sizing: border-box;
}

.CalendarFirstRow{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 10%;
}

.CalendarOptionsContainer{
  display: flex;
  flex-direction: column;
  width: 15%;
  height: 100%;
}

.CalendarSecondRow{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
}

.CalendarContainer {
    width: 85%;
    height: 100%;
    border: 1px solid #333;
    font-family: Arial, sans-serif;
    color: white;
}

.ItemCalendarDropdown{
  padding-left: 1rem;
  border-radius: 1rem;
}

.CalendarDropdown{
  display: flex;
}

