.ModalContainerOpen {
	display: flex;
	position: absolute;
	align-items: center;
	justify-content: center;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

}

.ModalContainerClosed {
	position: absolute;
	align-items: center;
	justify-content: center;
	z-index: 0;
	width: 100%;
	height: 100%;
	display: none;
}

.ModalContainerOpen::before {
	content: '';
	/* Cria um pseudo-elemento para o fundo */
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	/* Cor de fundo com opacidade reduzida */
	backdrop-filter: blur(5px);
	/* Opcional: Aplica um efeito de desfoque */
	z-index: -1;
	/* Coloca o fundo atrás do conteúdo */
	
}

.Modal {
	display: flex;
	flex-direction: column;
	position: absolute;
	background-color: #383636;
	width: 50%;
	height: 50%;
	border-radius: 2rem;
	margin-left: 16.5rem;
	align-self: center;
	align-items: center;
	opacity: 0.9;
}

.PageModal {
	width: 25rem;
	height: 12rem;
	margin-right: 16rem;
}

.PageModalText {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
}

.PageModalButton {
	display: flex;
	flex-direction: column;
	width: 5rem;
	background-color: #F36934;
	color: white;
	border-radius: 1rem;
	align-items: center;
	justify-content: center;
}