.skeleton {
    width: 100%;
    height: 100%;
    background: transparent;
    overflow: hidden;
}

.skeleton-text1 {
    height: 25%;
    max-height: 50px;
    width: 50%;
    margin-bottom: 1.1rem;
    background: linear-gradient(to right, #444 8%, #555 18%, #444 33%);
    background-size: 800px 104px;
    animation: loading 1.5s infinite;
}

.skeleton-text2 {
    height: 20%;
    max-height: 50px;
    width: 95%;
    margin-bottom: 1.1rem;
    background: linear-gradient(to right, #444 8%, #555 18%, #444 33%);
    background-size: 800px 104px;
    animation: loading 1.5s infinite;
}

.skeleton-text3 {
    height: 24%;
    width: 75%;
    max-height: 50px;
    margin-bottom: 1.1rem;
    background: linear-gradient(to right, #444 8%, #555 18%, #444 33%);
    background-size: 800px 104px;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    to {
        background-position: 100% 50%;
    }
}
  