.mapContainer{
    width: 100vw;
    height: 100vh;
    background-color: #2c2c2c;    
}

.map-container {
    height: 400px;
}

.marker-popup {
    display: flex;
    flex-direction: column;
    background-color: #F1F1F1;
    padding: 10px;
    border-radius: 20px;
    position: absolute;
    z-index: 1000;
    width: 15rem;
    max-height: 20rem;
    align-items: center;
    box-shadow: 0 30px 30px rgba(0,0,0,0.2);
}

.marker-popup:hover {
    transform: scale(1.015);
    cursor: pointer;
}

.placeholder_Map {
    background-color: #FFFFFF;
    width: 100%;
    max-width: 150px;
    height: auto; 
    border-radius: 2px;
}

.popup-text {
    font-weight: bold;
    font-size: 24px;
}

.popup-subtext {
    font-size: 12px;
    margin-top: -1rem;
}

.close-text{
    font-size: 20px;
    margin-top: -5px;
    margin-left: 3px;
    color: white;
}

.plantLogo {
    width: 100%;
    max-width: 150px;
    max-height: 100px;
    display: block;
    align-self: center;
    padding-top: 2rem;
}

.wrapper_X {
    background-color: #848282;
    margin-top: 4px;
    margin-left: -185px;
    height: 1rem;
    width: 1rem;
    border-radius: 10rem;
}

.wrapper_X:hover {
    cursor: pointer;
}

.barra_Map {
    width: 100%; 
    height: 13px; 
    background: linear-gradient(to right, #F36934 50%, #E5E0DF 50%); 
    border-radius: 3px;
}

.wrapper_Map {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper_Paragraph {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.subtext_Map {
    font-size: 16px;
    color: #F36934;
    align-self: center;
    padding-bottom: 10px;
}

.list_Map {
    background-color: #383636;
    color: #F5F5F5;
    border-radius: 15px;
    top: 89%;
    left: 55%;
    padding: 5px 20px;
    position: absolute;
    z-index: 1000;
}

.list_Map:hover {
    cursor: pointer;
    transform: scale(1.03);
    opacity: 0.9;
}

.icon_Map {
    padding-left: 5px;
}

.iconfilter_Map {
    display: flex;
    z-index: 1000;
    font-size: 1.5rem;
    color: #2c2c2c;
    border-radius: 5px;
    padding: 5px;
}

.iconfilter_Map:hover {
    cursor: pointer;
    transform: scale(1.05);
    background-color: #F1F1F1;
}

.search-wrapper_Map {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 10rem;
    width: 100%;
}

.filterBar_map {
    z-index: 100;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: rgba(56, 54, 54, 0.9);
    height: 100%;
    width: 15%;
    margin-left: 85%;
}

.cmplogo_filterBar {
    display: flex;
    flex-direction: column;
    height: 16vw;
    width: 100%;
    margin-top: 25%;
    justify-content: center;
}

.cmpname_filterBar {
    display: flex;
    font-size: calc(7px + .5vw);
    color: white;
    padding-top: 5%;
    justify-content: center;
}

.dropbox_filterBar {
    width: 85%;
}

.dropboxwrapper_filterBar {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 30%;
    margin-top: 10%;
}

.searchbutton_filterBar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon_filterBar {
    color: white;
    opacity: 0.5;
    font-size: calc(10px + .5vw);
    margin-top: 5%;
    margin-left: 5%;
}

.icon_filterBar:hover {
    cursor: pointer;
    transform: scale(1.03);
    opacity: 0.3;
}

.searchBox_map {
    z-index: 1000;
    width: 35%;
    position: relative;
}

.sub-wrapper_map {
    z-index: 1000;
    top: 3%;
    left: 95%;
    width: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10%;
}

.sub-wrapper_map:hover {
    cursor: pointer;
    transform: scale(1.05);
    background-color: #F1F1F1;
}

.sub-wrapper_floor{
    z-index: 1000;
    top: 14%;
    left: 95%;
    width: 2rem;
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: white;
    border-radius: 10%;
}

.sub-wrapper_floor:hover {
    cursor: pointer;
    transform: scale(1.05);
    background-color: #F1F1F1;
}

.iconmarker_Map {
    display: flex;
    z-index: 1000;
    font-size: 1.3rem;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    
}

.map-style-wrapper {
    z-index: 1000;
    top: 7%;
    left: 93.6%;
    position: absolute;
    background-color: white;
    border-radius: 10%;
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.map-style {
    padding: 0px 5px;
}

.map-style:hover {
    cursor: pointer;
    background-color: #F1F1F1;
}

.marker-wrapper {
    z-index: 99999999;
    left: 91.5%;
    width: 5rem;
    position: relative;
    top: 0;
    background-color: white;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.subtitle-marker {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.marker-wrapper_floor {
    z-index: 1000;
    left: 94%;
    position: relative;
    top: 0;
    background-color: white;
    border-radius: 10%;
    display: flex;
    flex-direction: column;
    padding: 5px;
}

.CenterHome{
    display: flex;
    position: absolute;
    bottom: 6%;
    right: 3%;
    background-color: white;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
    z-index: 99999999;
    cursor: pointer;
}

.CenterHomeIcon{
    display: flex;
    height: 50%;
    color: black;
}