#container {
    background-color: #2c2c2c;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
}

#content {
    width: 60%;
    max-width: 768px;
    /* border: 1px solid red; */
    text-align: justify;
}

#title {
    color: #fafafa;
    font-size: 32px;
    text-align: center;
}

#policy-text {
    line-height: 25px;
    color: gray;
}