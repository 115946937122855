@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

.loadingCircle{
    display: flex;
    flex-direction: column;
    border: 3px solid #f3f3f3; /* Cor de fundo */
    border-top: 3px solid gray; /* Cor da borda (parte do círculo que gira) */
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
    align-self: center;
    opacity: .8;
  }