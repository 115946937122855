.ScrollViewContainer{
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

.ScrollViewContainer::-webkit-scrollbar {
    width: 2px;
}
  
.ScrollViewContainer::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}