

.Search {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #2C2C2C;
    justify-content: center;
}

.content_Explore {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.content-wrapper_Explore {
    display: flex;
    flex-direction: column;
    width: 70%; 
    height: 85%;
}

.myinput{
    display: flex;
    border-width: 0;
    width: 100%;    
    height: 2rem;   
    outline: none;
    border-radius: 1rem;
    padding-left: 0.5rem;
    border-color: #fff;
    font-size: .8rem;
}

.dropDownSearch{
    width: 20%;
}

.upperSide_Explore{
    background-color: #383636;
    border-radius: 10px;
    overflow: auto;

    box-sizing: border-box;
    padding: 3rem;

    margin-bottom: 2vh;
    width: 100%;
    height: 90%;
}

.buttonWrapper_Explore {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10%;
    align-items: flex-end;
}

.middleSide_Explore {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-self: center;
}

.searchBlock_Explore {
    border-radius: 20px;
    background-color: #F5F5F5;
    margin-bottom: 2rem;
    width: 30rem;
    margin-top: 1.5rem;
    &:first-child {
        margin-right: 25px;
    }
    transition: max-height 0.3s ease;
    overflow-y: auto;  /* Isso permite a rolagem vertical */
    overflow-x: hidden;  /* Isso permite a rolagem vertical */
    scrollbar-width: thin; /* "thin" ou "auto" ou "none" */
    scrollbar-color: #888 #383636; /* thumb and track color */
}

.searchBlock_Explore:-webkit-scrollbar {
    width: 4px;
}

.searchBlock_Explore::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.searchBlock_Explore::-webkit-scrollbar-thumb:hover {
    background-color: #383636;
}

.searchBlock_Explore:not(.clicado):hover {
    background-color: #F5F5F5;
    cursor: pointer;
}

.searchBlock_Explore.clicado {
    max-height: 14rem;  
}

.itemsText_Explore {
    display: none;
}

.itemsText_Explore.clicado {
    display: block;
    color: 2C2C2C;
    font-size: 16px;
    padding: 10px;
    padding-left: 35px;
}

.itemsText_Explore.clicado:hover {
    background-color: #F36934;
    border-radius: 10px;
    cursor: pointer;
    color: white;
}

.horiozontalLine_Explore {
    display: none;
}

.searchText_Explore {
    color: 2C2C2C;
    font-size: 20px;
    padding: 15px;
    padding-left: 35px;
}

.searchText_Explore.clicado {
    cursor: pointer;
}

.explore_text {
    width: 56rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
    height: 1.5rem; /* Ajuste conforme necessário para diferentes tamanhos de tela */
    margin-bottom: 2rem; /* Espaçamento inferior */
    background-color: #FFFFFF;
    border-radius: 1rem; /* Bordas arredondadas */
    color: black;
    font-size: 0.8rem; /* Tamanho da fonte base em 'rem' para elementos internos */
    padding: 0.5rem 0.7rem; /* Espaçamento interno */
    padding-left: 3.5rem;
}

.icon_Explore {
    color: #2C2C2C;
    align-self: center;
    transform: translateX(180%) translateY(145%);
    font-size: 1.5em; 
    margin-left:-1.6rem;
}

.text-wraper {
    display: flex;
    flex-direction: row;
    align-items: center;   
    justify-content: space-between;
    margin-bottom: 0.6rem;
}

.hiperlink_Explore {
    color: #F36934;
    text-decoration: underline;
    background-color: #383636;
    border: none;
}

.hiperlink_Explore:hover {
    background-color: #383636;
    font-weight: bold;
    font-style: italic;
    cursor: pointer;
}

.searchImages_Explore {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width:  100%;
    height: 13rem;
}

.placeholder_Explore {
    background-color: #FFFFFF;
    width: 220px;
    height: auto; 
    border-radius: 2px;
}

.inputSearchContainer{
    display: flex;
    flex-direction: row;
}

.button_Explore {
    position: relative;
    width: 20%;
    max-width: 1000px;  
    padding: 0.625rem;
    background-color: #F36934; /* Cor de fundo do botão */
    color: #fff; /* Cor do texto do botão */
    border: none;
    cursor: pointer;
    border-radius: 0.625rem;
}
  
.button_Explore:hover {
    background-color: #C25429; /* Cor de fundo do botão ao passar o mouse */
}

.img_Search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #C4C4C4;
    border-radius: 1rem;
    align-self: center;
    cursor: pointer;
    width: 13rem;
    height: 9rem;
}

.img_Search:hover {
    cursor: pointer;
    transform: scale(1.03);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.imgWrapper_Search{
    display: flex;
    flex-direction: column;
    color: #F5F5F5;
    justify-content: center;
    align-items: center;
}

.underText_Search {
    padding-top: 0.4rem;
}

.inputContainer_Search{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 3.2rem;
    justify-content: space-between;
    border-radius: 1rem;
    background-color: white;
    align-items: center;
    align-self: center;
}

