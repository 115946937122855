.switch {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 25px;
  }
  
  .switch-input {
    display: none;
  }
  
  .switch-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #C4C4C4;
    border-radius: 25px;
    cursor: pointer;
    transition: 0.4s;
  }
  
  .switch-slider.on {
    background-color: #f36934;
  }
  
  .switch-slider::before {
    content: "";
    position: absolute;
    height: 21px;
    width: 21px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .switch-slider.on::before {
    transform: translateX(24px);
  }
  