.MainFilterContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: transparent;
    align-self: flex-end;
}

.Filter{
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 80vh;
    align-self: center;
    padding: 5%;
    padding-top: 2%;
    padding-bottom: 2%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    background-color: #383636;
    border-radius: 1.5rem;
    overflow: auto;
}

.FilterButton{
    display: flex;
    width: 100%;
    border-radius: .5rem;
    align-items: center;
    justify-content: center;
    background-color: #F36934;
    color: white
}

.FilterText{
    display: flex;
    font-size: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}