/* Container do DatePicker */
.datepicker {
    position: relative;
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    height: 3.3rem;
    border-radius: 1rem;
    padding-left: 1rem;
    box-sizing: border-box;
    font-size: 0.9rem; /* Tamanho da fonte base em 'rem' para elementos internos */
    background-color: #fff; /* Garante o fundo branco */
}

.datepicker input {
    width: 100%;
    height: 100%;
    border: none;
    margin: 17px -3px;
    font-size: 13px; /* Tamanho de fonte para o input */
    outline: none; /* Remove o outline ao focar */
}

.datepicker input::placeholder {
    color: transparent;
}

.datepicker label {
    position: absolute;
    left: 12px; /* Ajuste conforme necessário para alinhar com o texto de entrada */
    top: 1px; /* Posicione próximo à base do input inicialmente */
    pointer-events: none;
    color: #cccccc;
    font-size: 10px; /* Assegure que o tamanho da fonte inicial seja igual ao do input */
}



.datepicker .option {
    position: absolute;
    width: 100%;
    top: 3rem;
    left: 0; /* Adicione esta linha para garantir que o elemento .datepicker comece da borda esquerda */
    background-color: #fff;
    border-radius: 1rem;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: 0.15s;
}

.active .option {
    visibility: visible;
    opacity: 1;
    z-index: 100;
}

.datepicker .option div {
    padding: 0.75rem;
    cursor: pointer;
}

.datepicker .option div:hover {
    background-color: #f36934;
    color: #f5f5f5;
    border-radius: 1rem;
}

/* Esconder a seta do seletor de data no input */
.datepicker input[type="text"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

/* Esconder a seta em navegadores Firefox */
.datepicker input[type="text"]::-moz-calendar-picker-indicator {
    display: none;
}

/* Esconder a seta em navegadores IE e Edge */
.datepicker input[type="text"]::-ms-expand {
    display: none;
}

/* CSS específico para react-datepicker */
.datepicker .react-datepicker-wrapper {
    width: 100%;
}

.datepicker .react-datepicker__input-container input {
    width: 95%;
    height: 100%;
    border: none;
    margin: 17px -3px;
    font-size: 13px;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.datepicker .react-datepicker__input-container:hover input {
    cursor: pointer;
}

.datepicker .react-datepicker__input-container input::-webkit-calendar-picker-indicator {
    display: none;
}
