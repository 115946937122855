/* src/components/Sidebar.css */

.sidebar {
	min-width: 120px;
	width: 14vw;
	height: 100%;
	background-color: #383636;
	color: #f5f5f5;
	padding: 1vw;
	padding-top: 0;
	box-sizing: border-box;
	position: fixed;
	left: 0;
	top: 0;
	border-right: 1px solid #f5f5f5;
	z-index: 1;
}

.sidebar p, .sidebar span {
	font-size: 90%;
}

.linkStyle {
	text-decoration: none;
}

.button-text {
	margin-left: 6%;
	/* Adicione a quantidade de espaçamento desejada entre o ícone e o texto */
	font-size: calc(10px + .3vw);
}

.subitem {
	padding-left: 19.5%;
}

.subname {
	font-size: calc(10px + .3vw);
}

.sideline {
	height: 1%;
	background-color: #383636;;
	width: 70%;
	margin-top: 7px;
	margin-bottom: 7px;
	margin-right: 10%;
}

.subitens {
	display: flex;
	width: 95%;
	flex-direction: column;
	background-color: #D9D9D9;
	color: #2C2C2C;
	margin-bottom: 10px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	padding-bottom: 10px;
}

.item_disabled {
	color: #2C2C2C;
	border: none;
	/* Remove a borda padrão do botão */
	width: 20px;
	/* Largura do botão (ajuste conforme necessário) */
	height: 20px;
	/* Altura do botão (ajuste conforme necessário) */
	font-size: 12px;
	/* Tamanho da fonte do texto (ajuste conforme necessário) */
	background-color: #D9D9D9;
	float: right;
	margin-right: 11%;
	opacity: 0.5;
	cursor: not-allowed;
}

.item_disabled:hover {
	background-color: #D9D9D9;
}


button.sidearrow {
	color: #2C2C2C;
	border: none;
	/* Remove a borda padrão do botão */
	width: 20px;
	/* Largura do botão (ajuste conforme necessário) */
	height: 20px;
	/* Altura do botão (ajuste conforme necessário) */
	cursor: pointer;
	/* Transforma o cursor em uma mão quando passa sobre o botão */
	font-size: 12px;
	/* Tamanho da fonte do texto (ajuste conforme necessário) */
	background-color: #D9D9D9;
	float: right;
	margin-right: 11%;
}

button.sidearrow:hover {
	background-color: #383636;
	color: #F5F5F5;
	/* Cor do texto do botão */
	border-radius: 5px;
}

img.sideLogo {
	width: 90%;
	max-width: 200px;
	display: block;
	align-self: center;
	padding-top: 4rem;
}

button.sideButtonInitial {
	background-color: #383636;
	color: #F5F5F5;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	width: 95%;
	cursor: pointer;
	font-size: calc(10px + .3vw);
	align-items: center;
	display: flex;
}

button.sideButtonInitial:hover {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonInitial.active {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonRegister_disalowed {
	background-color: #383636;
	color: #666666;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	width: 95%;
	cursor: pointer;
	font-size: calc(10px + .3vw);
	align-items: center;
	display: flex;
}

button.sideButtonRegister_disalowed:hover {
	background-color: #666666;
	color: #2C2C2C;
	cursor: not-allowed;
}

button.sideButtonRegister {
	background-color: #383636;
	color: #F5F5F5;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	width: 95%;
	cursor: pointer;
	font-size: calc(10px + .3vw);
	align-items: center;
	display: flex;
}

button.sideButtonRegister:hover {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonRegister.active {
	background-color: #D9D9D9;
	color: #2C2C2C;
	margin-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	padding-bottom: 15px;
}

button.sideButtonRegister.drop-inactive {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonExplore {
	background-color: #383636;
	color: #F5F5F5;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	width: 95%;
	cursor: pointer;
	font-size: calc(10px + .3vw);
	align-items: center;
	display: flex;
}

button.sideButtonExplore:hover {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonExplore.active {
	background-color: #D9D9D9;
	color: #2C2C2C;
}


button.sideButtonManagement {
	background-color: #383636;
	color: #F5F5F5;
	border: none;
	border-radius: 5px;
	padding: 10px;
	margin-bottom: 10px;
	width: 95%;
	cursor: pointer;
	font-size: calc(10px + .3vw);
	align-items: center;
	display: flex;
}

button.sideButtonManagement:hover {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButtonManagement.active {
	background-color: #D9D9D9;
	color: #2C2C2C;
	margin-bottom: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	padding-bottom: 15px;
}

button.sideButtonManagement.drop-inactive2 {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

button.sideButton.active {
	background-color: #D9D9D9;
	color: #2C2C2C;
}

.sideBarVersion {
	position: fixed;
	bottom: 1rem;
	left: 1rem;
	color: #ccc;
	font-size: calc(10px + .3vw);
}